import * as React from "react"
import { useRef } from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./index.css"
import Seo from "../components/seo"
import BlurbCard from "../components/blurbCard"

import hallpic from "../images/brand/hall.jpeg"
import violinpic from "../images/brand/violin.jpeg"
import cellopic from "../images/brand/cello.jpeg"
import pianopic from "../images/brand/piano.jpg"
import circlesGroupPic from "../images/quote/circles_group.jpg"
import mdvPic from "../images/quote/mdv.jpg"

import Footer from "../components/footer"

// const { Meta } = Card

// const searchClient = algoliasearch(
//   "5C0BCXAZN7",
//   "ce4b34cf15b89400068e9dec379457c2"
// )

const url = "https://dev.d231r64idoboor.amplifyapp.com"
const imageUrl = `${url}${cellopic}`

const IndexPage = ({ data }) => {
  const searchForm = useRef(null)

  const handleClickEvent = event => {
    event.preventDefault()
    const form = searchForm.current
    navigate("/search", {
      state: { sale: false, entry: `${form["entry"].value}` },
    })
  }

  const handleKeypress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      navigate("/search", {
        state: { sale: false, entry: e.target.value },
      })
    }
  }

  return (
    <div className="flex flex-col h-screen justify-between">

      <Seo image={imageUrl} title="Welcome"  />

      {/* <Helmet>
        <meta property="og:image" content={imageUrl} />
      </Helmet> */}
      <main>
        <div className="bg-hero-pattern h-96 -mb-100"></div>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1080,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <div className="pt-24 flex justify-center">
            <div className="">
              <span>
                <StaticImage
                  src="../images/imir_logo.png"
                  width={120}
                  quality={100}
                  alt="FMI Logo"
                />
                <span> </span>
                {/* <StaticImage
                  src="../images/ficec-logo.png"
                  width={110}
                  quality={100}
                  alt="FICEC Logo"
                /> */}
              </span>
            </div>

            {/* Dropdown Menu Hided at Phase 1 Dev */}
            {/* <div className="flex items-center  ">
              <NavMenu />
            </div> */}
          </div>

          <div className="text-white text-center font-extrabold pt-8 pb-10 text-3xl sm:text-4xl ">
          FindMyInstrument™
          <div className="pt-5 text-xl sm:text-2xl font-semibold">
          <p>Protect ┃ Identify ┃ Share</p>
          <p>Identifying musical instruments has never been easier</p>
          </div>
         
          </div>

          {/* <div className="flex flex-row w-full">
            <div className="w-full">
              <form ref={searchForm}>
                <input
                  onKeyPress={handleKeypress}
                  className="w-full rounded-l p-4"
                  type="text"
                  placeholder="Search: Name, Maker, Year, Type, Country, City"
                  label={"entry"}
                  name={"entry"}
                ></input>
              </form>
            </div>

            <div>
              <button
                onClick={handleClickEvent}
                className="bg-red-500 hover:bg-red-400 rounded-r py-4 text-white font-bold pl-3 pr-3"
              >
                Search
              </button>
            </div>
          </div> */}

          {/* <div className="flex flex-row flex-grow justify-between">
            <form ref={searchForm}>
              <input
                type="text"
                className="w-10/12 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none transition-colors"
                placeholder="Search: Name, Maker, Year, Type, Country, City"
                label={"entry"}
                name={"entry"}
              />
            </form> */}
          {/* <button onClick={handleClickEvent} className="bg-white">
              search
            </button> */}
          {/* </div> */}

          <div className="pt-28">
            <p className="font-medium  text-lg text-center ">
            Welcome to FindMyInstrument™: A public international registry that serves as an informational and educational resource for makers, instrument owners, experts, dealers, insurers, teachers, and musicians.
            </p>

            <div className=" pt-9 pb-7 grid grid-cols-1 gap-4 sm:grid-cols-6 sm:gap-16">
              <div
                aria-hidden="true"
                onClick={() => {
                  navigate("/search", {
                    // state: { sale: false },
                  })
                }}
                onKeyDown={() => {
                  navigate("/search", {
                    // state: { sale: false },
                  })
                }}
                className="sm:col-start-1 sm:col-end-3  "
              >
                <BlurbCard
                  title="Browse Registry"
                  content="Enter one or more keywords or use the dropdown menus for a guided search."
                  imgUrl={hallpic}
            
                />
              </div>

                 <div
                aria-hidden="true"
                className=" sm:col-start-3 sm:col-end-5  "
              >
                <a href="https://classicallyconnected.org/syfir-cpr-registration-landing/" >
                <BlurbCard
                  title="Becoming a Content Provider of Record"
                  content="Learn how to become a Content Provider of Record (CPR). You need to become a CPR to register instruments."
                  imgUrl={pianopic}
                />
                </a>
                
              </div>


              <div
                aria-hidden="true"
        
                className="sm:col-start-5 sm:col-end-7 "
              >
                <a href="https://classicallyconnected.org/syfir-instrument-registry-landing/" >
                <BlurbCard
                  title="Register Instrument"
                  content="Are you already a CPR? Register your instrument here!"
                  imgUrl={violinpic}
                />
                </a>
             
              </div>

            </div>



<figure class="mx-auto max-w-screen-md text-center py-5">
    <svg aria-hidden="true" class="mx-auto mb-3 w-12 h-12 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg>
    <img class="mx-auto pt-2"  width="200" height="50" src={circlesGroupPic} alt="Circles Group Logo"/>
    <blockquote>
        <p class="text-xl italic font-medium text-gray-900 dark:text-white">“It is essential for insurers to be able to unambiguously identify the assets that they are asked to insure. FindMyInstrument™ can become a global ‘lookup reference’ for insurance professionals to track current and archival information about musical instruments before and during policy terms.”</p>
    </blockquote>
    <figcaption class="flex justify-center items-center mt-6 space-x-3">        
        <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
            <cite class="pr-3 font-medium text-gray-900 dark:text-white">Tommy Elliot</cite>
            <cite class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Regional Director, Asia-Pacific, Circles Group</cite>
        </div>
    </figcaption>  
</figure>

<figure class="mx-auto max-w-screen-md text-center py-5">
    {/* <svg aria-hidden="true" class="mx-auto mb-3 w-12 h-12 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/></svg> */}
    <img class="mx-auto pt-2"  width="100" height="50" src={mdvPic} alt="Circles Group Logo"/>
    <blockquote>
        <p class="text-xl italic font-medium text-gray-900 dark:text-white">“We are pleased to have registered some of our exhibited instruments—all of the winners of the Triennale International Competition—on FindMyInstrument™. Giving the public information on these works of art is a great service.”</p>
    </blockquote>
    <figcaption class="flex justify-center items-center mt-6 space-x-3">        
        <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
            <cite class="pr-3 font-medium text-gray-900 dark:text-white">Paolo Bodini</cite>
            <cite class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">CEO, Museo del Violino</cite>
        </div>
    </figcaption>  
</figure>

          </div>
        </div>
      </main>

      <Footer />
      {/* <footer className="bg-black">
        <div className="py-5 text-white flex flex-row justify-center ">
          v1.2.531 © {new Date().getFullYear()}&nbsp;
          <a className="text-white " href="https://www.si-yomusicsociety.org/">
            Si-Yo Music Society Foundation, Inc.index.js
          </a>
        </div>
      </footer> */}
    </div>
  )
}

export default IndexPage

// export const query = graphql`
//   {
//     allInstrument {
//       edges {
//         node {
//           invNumber
//           profileImg
//           name
//           maker
//         }
//       }
//     }
//   }
// `

// ** original graphql query the cards
// {<div className="grid  grid-cols-3 gap-1">
//   {data.allInstrument.edges.map(({ node, index }) => (
//     <div>
//       <CardResults
//         name={node.name}
//         maker={node.maker}
//         invNumber={node.invNumber}
//         profileImg={node.profileImg}
//         key={index}
//       />
//     </div>
//   ))}
//   </div> }
