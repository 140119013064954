import * as React from "react"

const BlurbCard = ({ title, content, imgUrl }) => (
  <div className="container h-full w-50 mx-auto cursor-pointer  bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transform hover:scale-105 duration-500">
    <img className="w-full" src={imgUrl} alt="" width="1000" height="700" />
    <div className="text-center relative pb-8 px-3">
      <h1 className="mb-1 text-xl font-semibold text-gray-700 hover:text-gray-900 ">
        {title}
      </h1>
      <span className="text-gray-700 hover:text-gray-900">{content}</span>
    </div>
  </div>
)

export default BlurbCard
